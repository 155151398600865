@mixin bodyFont300() {
	font-family: MuseoSansRounded-300, Arial, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin bodyFont500() {
	font-family: MuseoSansRounded-500, Arial, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin bodyFont700() {
	font-family: MuseoSansRounded-700, Arial, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin altFont500() {
	font-family: MuseoSlab-500, Arial, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin altFont700() {
	font-family: MuseoSlab-700, Arial, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}
