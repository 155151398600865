﻿@include defer {

	.br-typeahead .tt-suggestion > .product > .content > .price,
	.br-typeahead .tt-highlight {
		@include bodyFont700();
	}

	.br-typeahead .tt-suggestion > .product > .content .price__was {
		@include bodyFont500();
	}
}
