@include critical {

	.c-product-page__title,
	.c-product-page__need-help {
		@include altFont700();
	}

	.c-product-main-price {
		@include altFont700();
	}

	.safari .c-product-main-price__main__was::after,
	.safari .c-product-main-price__main .was::after,
	.mac .c-product-main-price__main__was::after,
	.mac .c-product-main-price__main .was::after {
		margin-top: -3px;
	}

	.c-product-page__subtitle {
		@include bodyFont500();
	}

	.c-product-page__link {
		@include bodyFont500();
	}

	.c-product-page__need-help-call {
		@include bodyFont500();
	}

	.c-product-page__variants__item__label {
		@include bodyFont700();
		font-size: rem(16px);
	}

	.c-product-page__add-to-basket-button {
		background-color: $colorBase;
	}
}

@include defer {

	.c-product-page__add-to-basket-button:hover,
	.c-product-page__add-to-basket-button:focus {
		background-color: #9B9B9B;
	}

}
