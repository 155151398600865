@include defer {

	.atb__main__added__message {

		@include mq-min($mAtbDesktopBp) {
			@include bodyFont700();
		}
	}

	.atb__main__added__title {

		@include mq-min($mAtbDesktopBp) {
			@include bodyFont700();
		}
	}

	.atb__main__added__title__price {

		@include mq-min($mAtbDesktopBp) {
			@include bodyFont700();
		}
	}

	.atb__main__header__message,
	.atb__main__title,
	.atb__main__product__title,
	.atb__main__footer__close,
	.atb__detail__item__title,
	.atb__detail__item__features,
	.atb__detail__item__specs {
		@include bodyFont700();
	}

	// Note: to line up buttons to within 1px of where they should be in Safari

	a.atb__quantity__add {
		padding-top: 10px;
		padding-bottom: 0;
		line-height: rem(16px);
	}


	.atb {

		.atb__detail__item__action a {
			text-decoration: underline;
			font-family: MuseoSansRounded-700, Arial, Helvetica, sans-serif;
		}
	}
}
