.c-header {

	@include critical {
		z-index: auto;
	}
}

.c-header__top {

	@include critical {
		background-image: none;

		@include mq-min($bpDesktop) {
			height: 50px;
		}
	}
}

.c-header__top__inner {

	@include critical {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.c-header__middle__inner {

	@include critical {

		@include mq-min($bpDesktop) {
			padding-bottom: 20px;
			padding-top: 20px;

			.is-trade & {
				padding-bottom: 10px;
				padding-top: 10px;
			}
		}
	}
}

.c-header__logo {

	@include critical {
		width: 150px;
		margin: 0;
		padding: 4px 0;

		@include mq-min(375px) {
			width: 175px;
		}

		@include mq-min($bpDesktop) {
			width: 348px;
			margin: 0 auto;
		}

		@include mq-max($bpSmallLogoHeaderMax) {
			width: 135px;
			position: relative;
			top: 4px;
		}
	}
}

.c-header__utils__link {

	@include critical {
		@include bodyFont700();
		font-size: rem(13px);
		line-height: rem(13px);
	}
}

.c-header__utils__count {

	@include critical {
		right: -8px;
		bottom: -8px;

		@include mq-max($bpDesktop - 1) {
			color: $overrideHeaderUtilsCountColor;
			padding: 5px 5px 3px 5px;
		}

		@include mq-min($bpDesktop) {
			right: 0;
			background: $colorBase;
			color: $colorWhite;
			line-height: rem(30px);
		}
	}
}

.c-header__call {

	@include critical {

		@include mq-min($bpDesktop) {
			position: relative;
			z-index: 1;
			top: 9px;
		}

		@include mq-min($bpMega) {
			top: 13px;
		}
	}
}


.c-header__call__link {

	@include critical {
		display: block;
		will-change: opacity;
		font-size: rem(18.4px);
		line-height: rem(18.4px);
		letter-spacing: 0;
		@include bodyFont500();

		@include mq-min($bpMega) {
			display: inline-block;
			font-size: rem(20px);
			line-height: rem(20px);
		}
	}

	&:hover,
	&:focus {

		@include defer {
			color: $overrideHeaderCallLinkHoverFocusColor;
		}
	}
}

.c-header__call__hours {

	@include critical {
		display: block;
		margin: 0;
		color: $overrideHeaderCallHoursColor;
		font-size: rem(11px);
		line-height: rem(11px);
		letter-spacing: 0.2px;
		padding: 2px 0 0 0;
		@include bodyFont500();

		@include mq-min($bpMega) {
			padding: 0 0 0 10px;
			display: inline-block;
			font-size: rem(13px);
			line-height: rem(13px);
		}
	}
}

.c-header__quick-links {

	@include critical {
		@include bodyFont500();
		font-size: rem(13px);
		line-height: rem(13px);
	
		@include mq-min($bpDesktop) {
			position: relative;
			z-index: 1;
			top: 18px;
		}
	}
}

.c-header__quick-links__item {

	@include critical {

		&:not(:first-child) {

			&:before {
				top: 0;
				bottom: 0;
			}
		}
	}
}

.c-header__quick-links__link {

	@include defer {
		transition: opacity 0.25s ease;
		will-change: opacity;

		&:hover,
		&:focus {
			color: $overrideHeaderQuickLinksLinkHoverFocusColor;
		}
	}
}

.c-header__logo__link,
.c-header__search__button,
.c-header__utils__link,
.c-header__utils__sub__link,
.c-header__search-toggle__link {

	@include defer {
		transition: opacity 0.25s ease;
		will-change: opacity;

		&:hover,
		&:focus {
			color: $overrideHeaderGenericLinkHoverFocusColor;
		}
	}
}

.c-header__utils__sub__link {

	&:hover,
	&:focus {

		@include defer {
			color: $overrideHeaderUtilsSubLinkHoverFocusColor;
		}
	}
}

.c-header__search {

	@include critical {
		transform: none;
		margin-top: -24px;
	}
}

.c-header__search__inner {

	@include critical {
		height: 48px;

		@include mq-min($bpMega) {
			width: 325px;
		}

		.br-typeahead {
			display: block !important;
		}
	}
}

.c-header__search__text {

	@include critical {
		height: 48px;
		border: 0;
		padding: 0 20px;
		@include bodyFont700();
		color: $colorBase;
		font-size: rem(13px);
		width: 100%;
		padding-right: 60px;
		@include box-sizing();

		@include mq-min($bpLarge) {

			@include mq-max($bpMega - 1) {
				padding-right: 40px;
				padding-left: 10px;
			}
		}
	}

	&::placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder {

		@include defer {
			opacity: 1;
			color: $colorBase;
		}
	}
}

.c-header__search__button {

	@include critical {
		height: 48px;
	}
}
