﻿.c-highlight-card__title {
	
	@include defer {
		@include bodyFont500();
	}
}

.c-highlight-card__subtitle {

	@include defer {
		@include bodyFont700();
	}
}