﻿@include critical {

	.js-tooltip-trigger {

		&:before {
			@include bodyFont700();

			@include mq-min($bpDesktop) {
				margin-top: -2px;
			}
		}
	}
}

@include defer {

	.js-tooltip__inner {

		.cms {

			a,
			p:first-child strong {
				@include bodyFont700();
			}
		}
	}

	.js-tooltip__close:before,
	.js-tooltip__close:after {
		margin-top: -2px;
	}
}