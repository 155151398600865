﻿.c-nav__link {

	@include critical {
		@include bodyFont500();

		@include mq-min($bpDesktop) {
			text-transform: uppercase;
			font-size: rem(13px);
			@include bodyFont700();
		}
	}
}

.c-nav__meganav-list-title,
.c-nav__meganav-title {
	@include critical {
		@include bodyFont500();
	}
}

.c-nav__meganav-title-link {

	&:hover,
	&:focus {

		@include defer {

			@include mq-min($bpDesktop) {
				color: $cNavMegaNavTitleColor;
			}
		}
	}
}

.c-nav__meganav-list-link {

	.no-touch &:hover {

		@include defer {

			@include mq-min($bpDesktop) {
				color: $cNavMegaNavTitleColor;
			}
		}
	}
}