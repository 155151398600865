@include defer {

	.c-footer {
		@include bodyFont300();
	}

	.c-footer__title {
		@include bodyFont700();
		color: $overrideFooterTitleColor;
	}


	.c-footer__top {
		background-color: $overrideFooterTopBackgroundColor;
	}

	.c-footer__social-link:hover,
	.c-footer__social-link:focus {
		color: $overrideFooterSocialContactLinkColor;
	}


	.c-footer__contact-link {
		@include bodyFont500();
	}

	.c-footer__contact-link--help-hub {
		@include bodyFont500();
	}

	.c-footer__logo {
		width: 250px;
		height: 35px;

		.is-trade & {
			width: 124px;
		}
	}

	.c-newsletter-signup__input {
		@include bodyFont300();
	}

	.c-newsletter-signup__button {
		@include bodyFont700();
	}

	.c-footer__inline-list {
		font-size: rem(13px);
		line-height: rem(17px);
	}

	.c-footer__copyright {
		font-size: rem(11px);
		line-height: rem(15px);

		@include mq-max($bpDesktop - 1) {
			padding: 0;
		}
	}

	.c-footer__contact {

		@include mq-min($bpMassive) {
			margin-top: 30px;
		}
	}

	.c-footer__bottom .c-footer__inline-list--has-seperator {

		@include mq-min($bpMassive) {
			padding-top: 30px;
		}
	}
}
