﻿.formText,
.formTextarea,
.formSelect,
.formUpload,
.c-basket__discount-input {

	@include critical {
		font-family: $fontBase;
	}

	&:focus {

		@include defer {
			box-shadow: none;
		}
	}
}

.formRow label {

	@include critical {
		padding: 0 0 10px 0;
	}
}

.formButton {

	@include critical {
		@include bodyFont500();
	}
}

.formButtonLarge,
.formButtonCheckout {

	@include critical {
		padding: 12px 25px !important; /* The original Tap style has important so this is also needed */
		height: 45px;
	}
}

.formButtonToggle {

	@include critical {
		background: none !important;
		border: 1px solid $colorBase;
		color: $colorBase !important;

		&:before {
			background: none;
			border: 2px solid $colorBase;
			border-width: 0 0 2px 2px;
			width: 8px;
			height: 8px;
			transform: rotate(-45deg);
			top: 13px;
		}
	}

	@include defer {

		&:hover {
			background: $colorBase !important;
			color: $colorWhite !important;

			&:before {
				border-color: $colorWhite;
			}
		}

		&.active {

			&:before {
				transform: rotate(135deg);
				top: 18px;
			}
		}
	}
}

/* Umbraco forms */

@include critical {

	.umbraco-forms-label {
		padding: 0 0 10px 0;
	}

	.umbraco-forms-form .checkboxlist, 
	.umbraco-forms-form .radiobuttonlist {
		margin-top: 0;
	}

	.umbraco-forms-form label .umbraco-forms-indicator {
		color: currentColor !important;
	}
}
