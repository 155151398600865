﻿@include defer {

	.m-mobile-search__form .formText,
	.m-mobile-search__results__title,
	.m-mobile-search__results__menu__link b,
	.m-mobile-search__results__menu__link mark,
	.m-mobile-search__results__menu__link i,
	.m-mobile-search__product__title b,
	.m-mobile-search__product__title mark,
	.m-mobile-search__product__price {
		@include bodyFont700();
	}

	.m-mobile-search__product__price .from {
		@include bodyFont300();
	}
}
